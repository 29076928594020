import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const Transformations = ({ site, sectionStyle }) => {

  const query = useStaticQuery(graphql`
    query {
      beforeImage: file(relativePath: { eq: "transformations/transformation-renovation-avant-mousse-gava.jpg" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      afterImage: file(relativePath: { eq: "transformations/transformation-renovation-apres-mousse-gava.jpg" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {sectionStyle === "1" &&
        <div id="transformations" className="wpo-transformation-area section-padding">
          <div className="container">
            <div className="row">
              <div className="wpo-section-title">
                <h2>Transformons votre projet en réussite</h2>
                <span>Que votre projet soit personnel ou professionnel notre équipe expérimentée et dynamique mettra tout en œuvre afin de l'accomplir dans les meilleurs conditions et délais.</span>
              </div>
            </div>
            <div className="row align-items-center">
              <ReactCompareSlider
                itemOne={
                  <ReactCompareSliderImage src={query.beforeImage.childImageSharp.fluid.srcWebp} srcSet={query.beforeImage.childImageSharp.fluid.srcSetWebp} alt={`Avant rénovation de la façade d'une maison par ${site.siteMetadata.title} à Roanne`} />
                }
                itemTwo={
                  <ReactCompareSliderImage src={query.afterImage.childImageSharp.fluid.srcWebp} srcSet={query.afterImage.childImageSharp.fluid.srcSetWebp} alt={`Après rénovation de la façade d'une maison par ${site.siteMetadata.title} à Roanne`} />
                }
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Transformations