import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby';

const CTASection = ({ site, sectionStyle }) => {

  const query = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "backgrounds/cta-mousse-gava.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set ImageData.
  const backgroundImage = query.backgroundImage.childImageSharp.fluid
  const backgroundImageStack = [
    backgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.5))`,
  ].reverse()

  return (
    <>
      {sectionStyle === "1" &&
        <BackgroundImage
          id="counts"
          Tag="section"
          className="static-cta"
          fluid={backgroundImageStack}
        >
          <div className="cta-container">
            <div className="cta-inner">
              <div className="container-fluid">
                <div className="row align-items-center justify-content-center">
                  <div className="col-xl-10 col-lg-10 col-md-12 col-12">
                    <div className="wpo-static-cta-inner">
                      <div className="cta-title">
                        <h2>Vous avez une question?</h2>
                      </div>
                      <div className="clearfix"></div>
                      <div className="cta-btns">
                        <Link to={`/contact/`} className="theme-btn-s2">Contactez-nous</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      }
    </>
  )
}

export default CTASection