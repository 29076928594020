import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const HeroSection = ({ site, sectionStyle }) => {
  return (
    <>
      {sectionStyle === "1" &&    
        <section className="static-hero">
          <div className="hero-container">
            <div className="hero-inner">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-7 col-12">
                    <div className="wpo-static-hero-inner">
                      <div className="slide-visible-text">
                        <h1>{site.siteMetadata.title}</h1>
                      </div>
                      <div className="slide-title">
                        <h2>Un cercle de <span>qualité</span></h2>
                      </div>
                      <div className="slide-btn">
                        <AnchorLink className="theme-btn" href="#services" aria-label="services">Découvrez nos services</AnchorLink>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="static-hero-right">
            <div className="static-hero-img">
              <div className="static-hero-img-inner">
                <StaticImage src="../../images/hero/un-cercle-de-qualite-mousse-gava.png" alt={`${site.siteMetadata.title} est un cercle de qualité`}/>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default HeroSection