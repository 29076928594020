import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Tilty from 'react-tilty';

const InterventionZoneSection = ({ site, sectionStyle }) => {
  return (
    <>
      {sectionStyle === "1" &&
        <div id="intervention-zone" className="wpo-about-area section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="wpo-about-text">
                  <div className="wpo-about-title">
                    <span>40km autour de Roanne</span>
                    <h2>Notre zone d'intervention</h2>
                  </div>
                  <p>{site.siteMetadata.title} intervient dans un rayon de 40km autour de Roanne pour vos projets en plâtrerie, peinture, décoration, carrelage, faïence, sols souples, façade et isolation thermique par l'intérieure.</p>
                  <div className="btns">
                    <Link to={`/contact/`} className="theme-btn">Contactez-nous</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="wpo-about-img">
                  <Tilty className="tilty" glare scale={1.05} maxGlare={0.5}>
                    <StaticImage src="../../images/intervention-zone/zone-d-intervention-mousse-gava.png" alt={`Zone d'intervention de ${site.siteMetadata.title} dans un rayon de 40km autour de Roanne : Le Coteau, Riorges, Perreux, Mably, Commelle-Vernay, Villerest, Saint-Léger-sur-Roanne, Parigny, Ouches, Pouilly-les-Nonains, Lentigny`} />
                  </Tilty>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default InterventionZoneSection